import { BreakpointsService } from '@aimmo-renew-home/shared/breakpoints/breakpoints.service';
import { BreakpointState } from '@angular/cdk/layout';
import { inject, Injectable, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AimmoCoreBreakpointService {
  public readonly isMobile = signal<boolean>(false);
  private readonly breakpointsService = inject(BreakpointsService);

  public get layoutChangesEvent$(): Observable<BreakpointState> {
    return this.breakpointsService.layoutChangesEvent$.pipe(
      tap(() => this.isMobile.set(this.breakpointsService.isPortableDevice))
    );
  }
}
